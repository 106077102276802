import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../styles/Header.css";
import logo1 from "../assets/images/logo1.png";
import logo2 from "../assets/images/logo2.png";

const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function (...args) {
    const context = this;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [clickedLink, setClickedLink] = useState("");
  const [menuOpen, setMenuOpen] = useState(false); // Track menu open state

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      if (scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

      // Reset the clicked link when scrolling
      if (clickedLink) {
        setClickedLink("");
      }
    };

    const throttledHandleScroll = throttle(handleScroll, 100);
    window.addEventListener("scroll", throttledHandleScroll);

    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [clickedLink]);

  const handleLinkClick = (e, targetId) => {
    e.preventDefault();
    setClickedLink(targetId);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const offset = -100;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = targetElement.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      if (targetId === "get-involved-section") {
        triggerCalendly3DEffect();
      }
    }

    setMenuOpen(false);

    setTimeout(() => {
      setClickedLink("");
    }, 500);
  };

  const triggerCalendly3DEffect = () => {
    const calendlyWidget = document.querySelector(".calendly-widget");
    if (calendlyWidget) {
      calendlyWidget.classList.add("enhanced-3d-effect");

      setTimeout(() => {
        calendlyWidget.classList.remove("enhanced-3d-effect");
      }, 600);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle menu state
  };

  return (
    <header
      className={`navbar navbar-expand-lg navbar-dark custom-header ${
        scrolled ? "custom-header-scrolled" : ""
      }`}
      role="banner"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 1000,
        transition: "background 0.3s ease",
      }}
    >
      <div className="container">
        <div className="navbar-brand">
          <a href="/" aria-label="Simplify Labs Home">
            <img
              src={scrolled ? logo2 : logo1}
              height="30"
              width="130"
              alt="Simplify Labs Logo"
              loading="lazy"
            />
          </a>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          aria-expanded={menuOpen}
          aria-label="Toggle navigation"
          onClick={toggleMenu}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <nav
          className={`collapse navbar-collapse ${menuOpen ? "show" : ""}`}
          id="navbarNav"
          aria-label="Primary Navigation"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  clickedLink === "features-section" ? "clicked" : ""
                }`}
                href="#features-section"
                onClick={(e) => handleLinkClick(e, "features-section")}
                aria-label="Navigate to Services section"
              >
                Services
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  clickedLink === "how-it-works-section" ? "clicked" : ""
                }`}
                href="#how-it-works-section"
                onClick={(e) => handleLinkClick(e, "how-it-works-section")}
                aria-label="Navigate to Method section"
              >
                Method
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  clickedLink === "ourBenefits-section" ? "clicked" : ""
                }`}
                href="#ourBenefits-section"
                onClick={(e) => handleLinkClick(e, "ourBenefits-section")}
                aria-label="Navigate to Our Twist section"
              >
                Our Twist
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  clickedLink === "faq-section" ? "clicked" : ""
                }`}
                href="#faq-section"
                onClick={(e) => handleLinkClick(e, "faq-section")}
                aria-label="Navigate to FAQ section"
              >
                FAQ
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  clickedLink === "find-out-more-section" ? "clicked" : ""
                }`}
                href="#find-out-more-section"
                onClick={(e) => handleLinkClick(e, "find-out-more-section")}
                aria-label="Navigate to Discover section"
              >
                Discover
              </a>
            </li>
          </ul>
        </nav>
        <button
          className="cta-button"
          onClick={(e) => handleLinkClick(e, "get-involved-section")}
          aria-label="Schedule a Free Consultation"
        >
          Schedule a Free Consultation
        </button>
      </div>
    </header>
  );
};

export default Header;
