import React from "react";
import { Helmet } from "react-helmet";
import CareerHero from "../components/CareersHero";
import "../styles/CareersHero.css";
import CareerImageBannerSection from "../components/CareerImageBannerSection";
import PerksAndBenefits from "../components/PerksAndBenefits";
import JobListingSection from "../components/JobListingSection";
import CareerContactBanner from "../components/CareerContactBanner";
import SummaryFooter from "../components/SummaryFooter";

const Careers = () => {
  return (
    <main id="careers-page">
      {/* SEO and Open Graph Tags */}
      <Helmet>
        <title>Careers | Join Our Team at Simplify Labs</title>
        <meta
          name="description"
          content="Explore exciting career opportunities at Simplify Labs. Join a team of innovators and help shape the future of AI-powered solutions."
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://simplifylabs.ai/careers" />

        {/* Open Graph Tags for Social Sharing */}
        <meta
          property="og:title"
          content="Careers | Join Our Team at Simplify Labs"
        />
        <meta
          property="og:description"
          content="Explore exciting career opportunities at Simplify Labs. Help shape the future of AI-powered solutions with us."
        />
        <meta
          property="og:image"
          content="https://simplifylabs.ai/careers-og-image.jpg"
        />
        <meta property="og:url" content="https://simplifylabs.ai/careers" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags for Social Sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Careers | Join Our Team at Simplify Labs"
        />
        <meta
          name="twitter:description"
          content="Join a passionate team of innovators at Simplify Labs. Explore career opportunities in AI and technology."
        />
        <meta
          name="twitter:image"
          content="https://simplifylabs.ai/careers-twitter-image.jpg"
        />

        {/* Schema.org JSON-LD for job postings */}
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "JobPosting",
            "title": "Full Stack Web Developer",
            "hiringOrganization": {
              "@type": "Organization",
              "name": "Simplify Labs",
              "sameAs": "https://simplifylabs.ai",
              "logo": "https://simplifylabs.ai/logo.png"
            },
            "jobLocation": {
              "@type": "Place",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "123 Innovation St",
                "addressLocality": "Austin",
                "addressRegion": "TX",
                "postalCode": "73301",
                "addressCountry": "US"
              }
            },
            "description": "We are looking for a Full Stack Web Developer to join our team at Simplify Labs. As a developer, you will work on AI-powered solutions that drive innovation.",
            "employmentType": "Full-Time",
            "datePosted": "2024-10-06",
            "validThrough": "2024-12-31",
            "baseSalary": {
              "@type": "MonetaryAmount",
              "currency": "USD",
              "value": "80000"
            }
          }
          `}
        </script>

        {/* Mobile Optimization */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      {/* Career Hero Section */}
      <section aria-label="Career Hero Section">
        <CareerHero />
      </section>

      {/* Career Image Banner Section */}
      <section aria-label="Career Image Banner Section">
        <CareerImageBannerSection />
      </section>

      {/* Perks and Benefits Section */}
      <section aria-label="Perks and Benefits Section">
        <PerksAndBenefits />
      </section>

      {/* Job Listings Section */}
      <section aria-label="Job Listings Section">
        <JobListingSection />
      </section>

      {/* Career Contact Banner Section */}
      <section aria-label="Career Contact Banner Section">
        <CareerContactBanner />
      </section>

      {/* Summary Footer Section */}
      <footer aria-label="Summary Footer Section">
        <SummaryFooter />
      </footer>
    </main>
  );
};

export default Careers;
