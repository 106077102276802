import React, { useEffect } from "react";
import useInViewAnimation from "../hooks/useInViewAnimation";
import { animated } from "react-spring";
import "../styles/CareersHero.css";
import careerImage from "../assets/images/careers1.jpg";

const CareersHero = () => {
  const { ref, animation } = useInViewAnimation(
    { opacity: 0, transform: "translateY(100px)" },
    { opacity: 1, transform: "translateY(0px)" }
  );

  // Ensure page starts at the top on refresh
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="careers-hero" aria-label="Careers Hero Section">
      <animated.div ref={ref} style={animation} className="container">
        <header className="title-section">
          <h1>Careers</h1>
        </header>

        <div className="content">
          <article className="text-section">
            <div className="headline">
              <h2 className="secondary-title">
                More than a job – it's where you make an impact
              </h2>
              <p>
                At Simplify Labs, we’re all about creating solutions that
                matter. Join a passionate team where your work drives innovation
                and transforms industries. Together, we'll simplify AI, build
                incredible products, and shape the future.
              </p>
              <p>
                Explore the freedom to grow, lead, and thrive in an environment
                that values creativity, collaboration, and cutting-edge ideas.
              </p>
            </div>
          </article>

          <div className="image-section">
            <img
              src={careerImage}
              alt="Join the Simplify Labs team and make an impact"
              aria-label="Career opportunities at Simplify Labs"
            />
          </div>
        </div>
      </animated.div>
    </section>
  );
};

export default CareersHero;
