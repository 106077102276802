import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer" aria-label="Main Footer">
      <div className="footer-container">
        <p className="footer-copyright">Simplify AI Labs © 2024</p>
        <nav aria-label="Social Media Links" className="footer-social-icons">
          <a
            href="#discord"
            className="footer-icon"
            aria-label="Join us on Discord"
          >
            <i className="fab fa-discord"></i>
          </a>
          <a
            href="#telegram"
            className="footer-icon"
            aria-label="Follow us on Telegram"
          >
            <i className="fab fa-telegram-plane"></i>
          </a>
          <a
            href="#twitter"
            className="footer-icon"
            aria-label="Follow us on Twitter"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="#youtube"
            className="footer-icon"
            aria-label="Follow us on YouTube"
          >
            <i className="fab fa-youtube"></i>
          </a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
