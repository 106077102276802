import React from "react";
import useInViewAnimation from "../hooks/useInViewAnimation";
import { animated } from "react-spring";
import "../styles/OurApproachSection.css";
import teamImage from "../assets/images/team1.jpg";

const OurApproachSection = () => {
  const { ref, animation } = useInViewAnimation(
    { opacity: 0, transform: "translateY(100px)" },
    { opacity: 1, transform: "translateY(0px)" }
  );

  return (
    <section className="our-approach" aria-label="Our Approach Section">
      <animated.div ref={ref} style={animation} className="container">
        <div className="text-content">
          <header>
            <h4>OUR APPROACH</h4>
            <h2>Driven by Innovation, Focused on Results</h2>
          </header>
          <article>
            <p>
              At Simplify Labs, we approach every challenge with a
              solutions-first mindset. Our team combines deep industry knowledge
              and cutting-edge AI technology to create impactful solutions that
              drive tangible results. From concept to deployment, we focus on
              innovation, efficiency, and delivering value to our clients.
            </p>
            <button
              className="btn"
              onClick={() => alert("Find Out More clicked!")}
              aria-label="Find out more about our approach"
            >
              Find Out More
            </button>
          </article>
        </div>
        <div className="image-content">
          <img
            src={teamImage}
            alt="Team working together at Simplify Labs"
            aria-label="Image of our team working together"
          />
        </div>
      </animated.div>
    </section>
  );
};

export default OurApproachSection;
