import React from "react";
import useInViewAnimation from "../hooks/useInViewAnimation";
import { animated } from "react-spring";
import "../styles/JobListingSection.css";

const jobs = [
  {
    title: "Full Stack Web Developer",
    location: "Austin, TX, USA",
  },
  {
    title: "Mobile UX Designer",
    location: "Austin, TX, USA",
  },
];

const JobListingSection = () => {
  const { ref, animation } = useInViewAnimation(
    { opacity: 0, transform: "translateY(100px)" },
    { opacity: 1, transform: "translateY(0px)" }
  );

  return (
    <section
      id="job-listings"
      className="job-listing-section"
      aria-label="Job Listing Section"
    >
      <animated.div
        ref={ref}
        style={animation}
        className="job-listing-container"
      >
        <header className="header">
          <h2>Career Opportunities</h2>
        </header>
        <div className="job-cards">
          {jobs.map((job, index) => (
            <article
              className="job-card"
              key={index}
              aria-label={`${job.title} position`}
            >
              <div className="job-info">
                <h4>{job.title}</h4>
                <p>{job.location}</p>
              </div>
              <div className="apply-button-wrapper">
                <button
                  className="apply-button"
                  aria-label={`Apply for ${job.title}`}
                >
                  Apply Now
                </button>
              </div>
            </article>
          ))}
        </div>
      </animated.div>
    </section>
  );
};

export default JobListingSection;
