import React from "react";
import useInViewAnimation from "../hooks/useInViewAnimation";
import { animated } from "react-spring";
import "../styles/OurValuesSection.css";

const values = [
  {
    icon: "fas fa-lightbulb",
    title: "Innovation",
    description:
      "We embrace new ideas and continually push boundaries, using AI to solve complex problems in creative ways.",
  },
  {
    icon: "fas fa-hands-helping",
    title: "Collaboration",
    description:
      "We believe in the power of working together. Our success is driven by collective effort, shared knowledge, and open communication.",
  },
  {
    icon: "fas fa-shield-alt",
    title: "Integrity",
    description:
      "We uphold the highest standards of honesty and transparency, building trust with our clients and within our team.",
  },
  {
    icon: "fas fa-chart-line",
    title: "Growth",
    description:
      "We are committed to personal and professional development, fostering an environment where everyone can grow and thrive.",
  },
  {
    icon: "fas fa-user-check",
    title: "Customer-Centric",
    description:
      "Our clients’ success is our success. We focus on creating solutions that deliver tangible value and drive their businesses forward.",
  },
  {
    icon: "fas fa-star",
    title: "Excellence",
    description:
      "We aim for excellence in everything we do, continuously improving and striving to be the best in the industry.",
  },
];

const OurValuesSection = () => {
  const { ref, animation } = useInViewAnimation(
    { opacity: 0, transform: "translateY(100px)" },
    { opacity: 1, transform: "translateY(0px)" }
  );

  return (
    <section
      id="our-values"
      className="our-values-section"
      aria-label="Our Values Section"
    >
      <animated.div
        ref={ref}
        style={animation}
        className="our-values-container"
      >
        <header className="header">
          <h2>Our Values</h2>
        </header>
        <div className="values-cards">
          {values.map((value, index) => (
            <article
              className="value-card"
              key={index}
              aria-label={value.title}
            >
              <div className="icon-wrapper" aria-hidden="true">
                <i className={value.icon}></i>
              </div>
              <h4>{value.title}</h4>
              <p>{value.description}</p>
            </article>
          ))}
        </div>
      </animated.div>
    </section>
  );
};

export default OurValuesSection;
