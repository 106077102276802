import React, { lazy, Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

// Lazy loading components for performance optimization
const HeroSection = lazy(() => import("../components/HeroSection"));
const FeaturesSection = lazy(() => import("../components/FeaturesSection"));
const AffiliateSection = lazy(() => import("../components/AffiliateSection"));
const HowItWorksSection = lazy(() => import("../components/HowItWorksSection"));
const FAQSection = lazy(() => import("../components/FAQSection"));
const FindOutMoreSection = lazy(() =>
  import("../components/FindOutMoreSection")
);
const GetInvolvedSection = lazy(() =>
  import("../components/GetInvolvedSection")
);
const OurBenefitsSection = lazy(() =>
  import("../components/OurBenefitsSection")
);

const Home = () => {
  const location = useLocation();
  const [sectionLoaded, setSectionLoaded] = useState(false); // Track if GetInvolvedSection is loaded

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = -100; // Adjust the offset as needed
      const sectionPosition =
        section.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: sectionPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const sectionId = location.hash.replace("#", "");
    if (sectionId && sectionLoaded) {
      scrollToSection(sectionId);
    }
  }, [location, sectionLoaded]);

  const handleSectionLoad = () => {
    setSectionLoaded(true);
  };

  return (
    <>
      {/* SEO Optimizations with Helmet */}
      <Helmet>
        <title>
          Simplify Labs - Streamlining Innovation | AI-driven Solutions for your
          Business
        </title>
        <meta
          name="description"
          content="Simplify Labs is a software agency in Austin, TX, offering AI-driven and custom tech solutions to help businesses achieve more with less complexity."
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://simplifylabs.ai/home" />

        {/* Open Graph Tags for Social Sharing */}
        <meta
          property="og:title"
          content="Simplify Your Path to Innovation | Simplify Labs"
        />
        <meta
          property="og:description"
          content="Simplify Labs offers AI-driven and custom tech solutions to help businesses in Austin, TX and beyond achieve more with less complexity."
        />
        <meta
          property="og:image"
          content="https://simplifylabs.ai/og-image.jpg"
        />
        <meta property="og:url" content="https://simplifylabs.ai/home" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags for Social Sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Simplify Your Path to Innovation | Simplify Labs"
        />
        <meta
          name="twitter:description"
          content="AI-driven and custom tech solutions for your business."
        />
        <meta
          name="twitter:image"
          content="https://simplifylabs.ai/twitter-image.jpg"
        />

        {/* Schema.org JSON-LD for structured data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Simplify Labs",
              "url": "https://simplifylabs.ai",
              "logo": "https://simplifylabs.ai/logo.png",
              "sameAs": [
                "https://www.linkedin.com/company/simplifylabs",
                "https://twitter.com/simplifylabs"
              ],
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-800-555-1234",
                "contactType": "Customer Support"
              }
            }
          `}
        </script>

        {/* Mobile Optimization */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      {/* Suspense for Lazy Loading */}
      <Suspense fallback={<div>Loading...</div>}>
        <div id="hero-section">
          <HeroSection />
        </div>
        <div id="features-section">
          <FeaturesSection />
        </div>
        <div id="affiliate-section">
          <AffiliateSection />
        </div>
        <div id="how-it-works-section">
          <HowItWorksSection />
        </div>
        <div id="ourBenefits-section">
          <OurBenefitsSection />
        </div>
        <div id="faq-section">
          <FAQSection />
        </div>
        <div id="find-out-more-section">
          <FindOutMoreSection />
        </div>
        <div id="get-involved-section">
          <GetInvolvedSection onSectionLoad={handleSectionLoad} />
        </div>
      </Suspense>
    </>
  );
};

export default Home;
