import React from "react";
import useInViewAnimation from "../hooks/useInViewAnimation";
import { animated } from "react-spring";
import "../styles/PerksAndBenefits.css";

const perks = [
  {
    icon: "fas fa-laptop-house",
    title: "Work From Anywhere",
    description:
      "Embrace flexibility. Whether you're at home, in a café, or on the go, work from the location that suits you best.",
  },
  {
    icon: "fas fa-microchip",
    title: "Cutting-Edge Tools",
    description:
      "We provide the latest tech, including top-tier Apple equipment, so you can stay at the forefront of innovation.",
  },
  {
    icon: "fas fa-users",
    title: "Collaborative Work Environment",
    description:
      "Our open culture encourages creativity and collaboration. Share ideas and build something meaningful with passionate peers.",
  },
  {
    icon: "fas fa-plane-departure",
    title: "Unlimited PTO",
    description:
      "We trust our team. Take time off whenever you need to recharge and return to work refreshed and motivated.",
  },
  {
    icon: "fas fa-hand-holding-usd",
    title: "Competitive Compensation & Equity",
    description:
      "Your work is valued. We offer competitive salaries along with equity options to ensure you share in our success.",
  },
  {
    icon: "fas fa-graduation-cap",
    title: "Learning & Development",
    description:
      "Grow with us. We offer access to courses, conferences, and learning resources to help you develop new skills and advance your career.",
  },
];

const PerksAndBenefits = () => {
  const { ref, animation } = useInViewAnimation(
    { opacity: 0, transform: "translateY(100px)" },
    { opacity: 1, transform: "translateY(0px)" }
  );

  return (
    <section
      id="perks-benefits"
      className="perks-benefits-section"
      aria-label="Perks and Benefits Section"
    >
      <animated.div
        ref={ref}
        style={animation}
        className="perks-benefits-container"
      >
        <header className="header">
          <h2>Perks and Benefits</h2>
        </header>
        <div className="perks-cards">
          {perks.map((perk, index) => (
            <article className="perk-card" key={index} aria-label={perk.title}>
              <div className="icon-wrapper" aria-hidden="true">
                <i className={perk.icon}></i>
              </div>
              <h4>{perk.title}</h4>
              <p>{perk.description}</p>
            </article>
          ))}
        </div>
      </animated.div>
    </section>
  );
};

export default PerksAndBenefits;
