import React from "react";
import useInViewAnimation from "../hooks/useInViewAnimation";
import { animated } from "react-spring";
import "../styles/SummaryFooter.css";

const SummaryFooter = () => {
  const { ref, animation } = useInViewAnimation(
    { opacity: 0, transform: "translateY(100px)" },
    { opacity: 1, transform: "translateY(0px)" }
  );

  return (
    <footer className="summary-footer" aria-label="Summary Footer">
      <animated.div ref={ref} style={animation} className="container">
        <div className="footer-content">
          {/* About Us Section */}
          <article className="footer-column about-us" aria-label="About Us">
            <h3>About Us</h3>
            <hr />
            <p>
              Simplify Labs is committed to making AI accessible and impactful
              for businesses. Our team of experts works collaboratively to build
              innovative solutions that drive growth and simplify the complex.
            </p>
          </article>

          {/* Contact Info Section */}
          <article
            className="footer-column contact-info"
            aria-label="Contact Info"
          >
            <h3>Contact Info</h3>
            <hr />
            <p>
              <i className="fas fa-envelope" aria-hidden="true"></i>{" "}
              contact@simplifylabs.ai
            </p>
            <p>
              <i className="fas fa-phone" aria-hidden="true"></i>{" "}
              +1-800-223-9881
            </p>
            <p className="location">
              <i className="fas fa-map-marker-alt" aria-hidden="true"></i>{" "}
              Austin, TX, USA
            </p>
            <h4>Be Social</h4>
            <hr />
            <div className="social-icons">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visit our Facebook"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visit our Twitter"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visit our Instagram"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </article>

          {/* Contact Us Form Section */}
          <article
            className="footer-column contact-us"
            aria-label="Contact Us Form"
          >
            <h3>Contact Us</h3>
            <hr />
            <form
              className="contact-form"
              action="mailto:contact@simplifylabs.ai"
              method="post"
              encType="text/plain"
              aria-label="Contact Us Form"
            >
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                required
              />

              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                required
              />

              <label htmlFor="message">Your Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Your Message"
                required
              ></textarea>

              <button type="submit" aria-label="Send message to Simplify Labs">
                Send
              </button>
            </form>
          </article>
        </div>
      </animated.div>
    </footer>
  );
};

export default SummaryFooter;
