import React from "react";
import useInViewAnimation from "../hooks/useInViewAnimation";
import { animated } from "react-spring";
import { Link } from "react-router-dom";
import "../styles/CallToActionSection.css";

const CallToActionSection = () => {
  const { ref, animation } = useInViewAnimation(
    { opacity: 0, transform: "translateY(100px)" },
    { opacity: 1, transform: "translateY(0px)" }
  );

  return (
    <section
      className="call-to-action-section"
      aria-label="Call to action section"
    >
      <animated.div ref={ref} style={animation} className="container">
        <article className="text-container">
          <h2>Ready to take your business to new heights?</h2>
          <p>
            Discover how our AI solutions can transform your startup or
            enterprise.
          </p>
        </article>
        <div className="button-container">
          <Link
            to="/"
            state={{ scrollTo: "get-involved-section" }}
            className="cta-button"
            aria-label="Get started and learn more about Simplify Labs"
          >
            Get Started
          </Link>
        </div>
      </animated.div>
    </section>
  );
};

export default CallToActionSection;
