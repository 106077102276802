import React from "react";
import ContactUsDetailsSection from "../components/ContactUsDetailsSection";
import CallToActionSection from "../components/CallToActionSection";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <main id="contact-us-page">
      {/* SEO Optimizations with Helmet */}
      <Helmet>
        <title>Contact Us | Simplify Labs</title>
        <meta
          name="description"
          content="Get in touch with Simplify Labs to learn more about our AI-driven solutions, partnerships, and how we can help grow your business."
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://simplifylabs.ai/contact-us" />

        {/* Open Graph Tags for Social Sharing */}
        <meta property="og:title" content="Contact Us | Simplify Labs" />
        <meta
          property="og:description"
          content="Reach out to Simplify Labs for AI-driven solutions to help grow your business. Contact us today for consultations, partnerships, and more."
        />
        <meta
          property="og:image"
          content="https://simplifylabs.ai/contact-og-image.jpg"
        />
        <meta property="og:url" content="https://simplifylabs.ai/contact-us" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags for Social Sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us | Simplify Labs" />
        <meta
          name="twitter:description"
          content="Get in touch with Simplify Labs to learn more about our AI-driven solutions and partnerships."
        />
        <meta
          name="twitter:image"
          content="https://simplifylabs.ai/contact-twitter-image.jpg"
        />

        {/* Schema.org JSON-LD for structured data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "ContactPage",
              "name": "Contact Us",
              "url": "https://simplifylabs.ai/contact-us",
              "description": "Get in touch with Simplify Labs to learn more about our AI-driven solutions and partnerships.",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-800-555-1234",
                "contactType": "Customer Service"
              },
              "sameAs": [
                "https://www.linkedin.com/company/simplifylabs",
                "https://twitter.com/simplifylabs"
              ]
            }
          `}
        </script>

        {/* Mobile Optimization */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      {/* Content Sections */}
      <ContactUsDetailsSection />
      <CallToActionSection />
    </main>
  );
};

export default ContactUs;
