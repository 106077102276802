import React from "react";
import { Helmet } from "react-helmet";
import AboutUsHero from "../components/AboutUsHero";
import OurApproachSection from "../components/OurApproachSection";
import WhatsOurSecretSection from "../components/WhatsOurSecretSection";
import OurValuesSection from "../components/OurValuesSection";
import AffiliateSection from "../components/AffiliateSection";
import CallToActionSection from "../components/CallToActionSection";
import SummaryFooter from "../components/SummaryFooter";
import "../styles/AboutUsHero.css";

const AboutUs = () => {
  return (
    <main id="about-us-page">
      {/* SEO and Open Graph Tags */}
      <Helmet>
        <title>About Us | Simplify Labs</title>
        <meta
          name="description"
          content="Learn more about Simplify Labs, our mission to simplify AI solutions for businesses, and the values that drive us."
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://simplifylabs.ai/about-us" />

        {/* Open Graph Tags for Social Sharing */}
        <meta property="og:title" content="About Us | Simplify Labs" />
        <meta
          property="og:description"
          content="Simplify Labs is dedicated to simplifying AI solutions for businesses. Learn about our mission and the values that drive our innovation."
        />
        <meta
          property="og:image"
          content="https://simplifylabs.ai/about-us-og-image.jpg"
        />
        <meta property="og:url" content="https://simplifylabs.ai/about-us" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags for Social Sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us | Simplify Labs" />
        <meta
          name="twitter:description"
          content="Learn about Simplify Labs, our mission to simplify AI for businesses, and the values that fuel our innovation."
        />
        <meta
          name="twitter:image"
          content="https://simplifylabs.ai/about-us-twitter-image.jpg"
        />

        {/* Schema.org JSON-LD for structured data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Simplify Labs",
              "url": "https://simplifylabs.ai",
              "logo": "https://simplifylabs.ai/logo.png",
              "description": "Simplify Labs is a tech company focused on providing AI-powered solutions to simplify business operations and drive innovation.",
              "sameAs": [
                "https://www.linkedin.com/company/simplifylabs",
                "https://twitter.com/simplifylabs"
              ],
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-800-555-1234",
                "contactType": "Customer Support"
              }
            }
          `}
        </script>

        {/* Mobile Optimization */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      {/* About Us Hero Section */}
      <section aria-label="About Us Hero Section">
        <AboutUsHero />
      </section>

      {/* Our Approach Section */}
      <section aria-label="Our Approach">
        <OurApproachSection />
      </section>

      {/* What's Our Secret Section */}
      <section aria-label="What's Our Secret?">
        <WhatsOurSecretSection />
      </section>

      {/* Our Values Section */}
      <section aria-label="Our Values">
        <OurValuesSection />
      </section>

      {/* Affiliate Section */}
      <section aria-label="Affiliate Section">
        <AffiliateSection />
      </section>

      {/* Call to Action Section */}
      <section aria-label="Call to Action">
        <CallToActionSection />
      </section>

      {/* Footer Section */}
      <footer aria-label="Summary Footer">
        <SummaryFooter />
      </footer>
    </main>
  );
};

export default AboutUs;
