import React from "react";
import useInViewAnimation from "../hooks/useInViewAnimation";
import { animated } from "react-spring";
import "../styles/WhatsOurSecretSection.css";
import person1 from "../assets/images/person1.jpg";
import person2 from "../assets/images/person2.jpg";
import person3 from "../assets/images/person3.jpg";

const WhatsOurSecretSection = () => {
  const { ref, animation } = useInViewAnimation(
    { opacity: 0, transform: "translateY(100px)" },
    { opacity: 1, transform: "translateY(0px)" }
  );

  return (
    <section
      className="whats-our-secret"
      aria-label="What's Our Secret Section"
    >
      <animated.div ref={ref} style={animation} className="container">
        <div className="image-content">
          <img
            src={person1}
            alt="Person 1 - Innovator at Simplify Labs"
            className="person-image"
            id="person1"
          />
          <img
            src={person2}
            alt="Person 2 - Creator at Simplify Labs"
            className="person-image"
            id="person2"
          />
          <img
            src={person3}
            alt="Person 3 - Problem-solver at Simplify Labs"
            className="person-image"
            id="person3"
          />
        </div>

        <div className="text-content">
          <header>
            <h4>THE HUMAN ELEMENT</h4>
            <h2>
              What's our secret?
              <br />
              It’s our people.
            </h2>
          </header>
          <article>
            <p>
              At Simplify Labs, our greatest strength is our team. We're a
              collective of innovators, creators, and problem-solvers who are
              passionate about building AI solutions that make a real
              difference. It's the creativity, dedication, and collaboration of
              our people that fuel our success and help us create groundbreaking
              solutions for our clients.
            </p>
            <button
              className="btn"
              onClick={() => alert("Meet the Team clicked!")}
              aria-label="Meet the Team at Simplify Labs"
            >
              Meet the Team
            </button>
          </article>
        </div>
      </animated.div>
    </section>
  );
};

export default WhatsOurSecretSection;
