import React from "react";
import useInViewAnimation from "../hooks/useInViewAnimation";
import { animated } from "react-spring";
import "../styles/CareerImageBannerSection.css";
import image1 from "../assets/images/person5.jpg";
import image2 from "../assets/images/person4.jpg";
import image3 from "../assets/images/person2.jpg";
import image4 from "../assets/images/person3.jpg";

const CareerImageBannerSection = () => {
  const { ref, animation } = useInViewAnimation(
    { opacity: 0, transform: "translateY(100px)" },
    { opacity: 1, transform: "translateY(0px)" }
  );

  return (
    <section
      className="career-image-banner"
      aria-label="Career Image Banner Section"
    >
      <animated.div ref={ref} style={animation} className="container">
        <div className="image-wrapper">
          <figure>
            <img
              src={image1}
              alt="Person 1 working at Simplify Labs"
              className="img img1"
              aria-label="Person 1 - Career at Simplify Labs"
            />
          </figure>
          <figure>
            <img
              src={image2}
              alt="Person 2 working at Simplify Labs"
              className="img img2"
              aria-label="Person 2 - Career at Simplify Labs"
            />
          </figure>
          <figure>
            <img
              src={image3}
              alt="Person 3 collaborating at Simplify Labs"
              className="img img3"
              aria-label="Person 3 - Career at Simplify Labs"
            />
          </figure>
          <figure>
            <img
              src={image4}
              alt="Person 4 collaborating at Simplify Labs"
              className="img img4"
              aria-label="Person 4 - Career at Simplify Labs"
            />
          </figure>
        </div>
      </animated.div>
    </section>
  );
};

export default CareerImageBannerSection;
