import React from "react";
import useInViewAnimation from "../hooks/useInViewAnimation";
import { animated } from "react-spring";
import "../styles/AboutUsHero.css";

const AboutUsHero = () => {
  const { ref, animation } = useInViewAnimation(
    { opacity: 0, transform: "translateY(100px)" },
    { opacity: 1, transform: "translateY(0px)" }
  );

  return (
    <section className="about-us-hero" aria-label="About Us Hero Section">
      <animated.div ref={ref} style={animation} className="container">
        <div className="content">
          <header className="title-section">
            <h1>About Us</h1>
          </header>

          <article className="text-section">
            <div className="headline">
              <h2>We’re Simplifying the Future of AI</h2>
              <p>
                At Simplify Labs, we’re dedicated to building AI-powered
                solutions that make businesses smarter and more efficient. Our
                team of innovators works across various industries to develop
                intelligent systems that drive growth, streamline operations,
                and unlock new opportunities. Join us on our mission to simplify
                the complex and create meaningful impact through cutting-edge
                technology.
              </p>
            </div>
          </article>

          <section className="stats" aria-label="Company statistics">
            <div className="stat">
              <h3>2021</h3>
              <p>Year we were founded</p>
            </div>
            <div className="stat">
              <h3>10k+</h3>
              <p>Lines of code powering our AI solutions</p>
            </div>
            <div className="stat">
              <h3>50+</h3>
              <p>Members in our distributed team</p>
            </div>
            <div className="stat">
              <h3>15</h3>
              <p>Countries where our solutions are deployed</p>
            </div>
          </section>
        </div>
      </animated.div>
    </section>
  );
};

export default AboutUsHero;
