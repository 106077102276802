import React from "react";
import useInViewAnimation from "../hooks/useInViewAnimation";
import { animated } from "react-spring";
import "../styles/ContactUsDetailsSection.css";

const ContactUsDetailsSection = () => {
  const { ref, animation } = useInViewAnimation(
    { opacity: 0, transform: "translateY(100px)" },
    { opacity: 1, transform: "translateY(0px)" }
  );

  return (
    <section className="contact-us-section">
      <animated.div
        ref={ref}
        style={animation}
        className="contact-us-container"
      >
        <header>
          <h2>Contact Us</h2>
        </header>
        <div className="contact-us-details">
          <p>
            We’re here to help you simplify AI for your business. <br /> Reach
            out to us by phone, email, or visit our office.
          </p>
          <p>We’d love to hear from you!</p>

          {/* Contact details */}
          <p aria-label="Phone number">
            <i className="fas fa-phone-alt" aria-hidden="true"></i> +1 (800)
            223-9189
          </p>
          <p aria-label="Email address">
            <i className="fas fa-envelope" aria-hidden="true"></i>{" "}
            support@simplifylabs.ai
          </p>
          <p aria-label="Location of Simplify Labs head office">
            <i className="fas fa-map-marker-alt" aria-hidden="true"></i> Head
            Office <br />
            Austin, Texas, United States
          </p>
        </div>

        {/* Contact form */}
        <div className="contact-us-form">
          <h3>Send us a message</h3>
          <form aria-label="Contact form">
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              id="name"
              placeholder="Your Name"
              required
              aria-required="true"
            />

            <label htmlFor="email">Your Email Address</label>
            <input
              type="email"
              id="email"
              placeholder="Your Email Address"
              required
              aria-required="true"
            />

            <label htmlFor="website">Your Website (optional)</label>
            <input
              type="text"
              id="website"
              placeholder="Your Website (optional)"
            />

            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              placeholder="Subject"
              required
              aria-required="true"
            />

            <label htmlFor="message">How can we help you?</label>
            <textarea
              id="message"
              placeholder="How can we help you?"
              required
              aria-required="true"
            ></textarea>

            <button type="submit" aria-label="Send message to Simplify Labs">
              Send Message
            </button>
          </form>
        </div>
      </animated.div>
    </section>
  );
};

export default ContactUsDetailsSection;
