import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Careers from "./pages/Careers";
import ScrollToTop from "./components/ScrollToTop";
import "./styles/global.css";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="app-wrapper">
        <div className="app-container">
          <div className="background-overlay"></div>
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/careers" element={<Careers />} />
              <Route
                path="/scheduleFreeCall"
                element={<NavigateToGetInvolved />}
              />
            </Routes>
          </main>
          <Footer />
        </div>
      </div>
    </Router>
  );
};

const NavigateToGetInvolved = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/#get-involved-section", { replace: true });
  }, [navigate]);

  return null;
};

export default App;
